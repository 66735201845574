<template>
  <v-card dark tile :loading="loading">
    <v-container fluid class="px-10 py-5">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="client"
          :error-messages="clientErrors"
          :counter="50"
          label="Nombre del cliente"
          required
          solo-inverted
          :rules="[rules.required, rules.counter50]"
        ></v-text-field>
        <v-text-field
          v-model="patent"
          :error-messages="patentErrors"
          :counter="15"
          label="Nº de patente"
          required
          solo-inverted
          :rules="[rules.required, rules.counter15]"
        ></v-text-field>
        <v-file-input
          v-model="file"
          accept="pdf/*"
          required
          label="Nuevo PDF"
          solo
          background-color="rgba(255, 255, 255, 0.16)"
        ></v-file-input>
        <v-text-field
          label="PDF Actual"
          readonly
          solo-inverted
          disabled
          hide-details
          class="mb"
          v-if="!!pdf"
        ></v-text-field>
        <iframe
          :src="pdf"
          width="100%"
          height="350px"
          class="mb-4 rounded-b"
          v-if="!!pdf"
        />
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Fecha"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              solo-inverted
              :rules="[rules.required]"
            ></v-text-field>
          </template>
          <v-date-picker
            dark
            v-model="date"
            @input="menu = false"
            locale="es"
          ></v-date-picker>
        </v-menu>
        <v-row class="mt-5 mb-0">
          <v-spacer></v-spacer>
          <v-btn plain :to="{ name: 'Admin' }"> Cancelar </v-btn>
          <v-btn @click="save" color="#e41a18"> Guardar </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <v-overlay :value="uploading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>
<script>
export default {
  name: "Service",
  data: (vm) => ({
    client: "",
    patent: "",
    date: new Date().toISOString().substr(0, 10),
    month: new Date().toISOString().substr(0, 7),
    pdf: "",
    menu: false,
    file: null,
    rules: {
      required: (value) => !!value || "Requerido.",
      counter50: (value) => value.length <= 50 || "50 carácteres máximos",
      counter15: (value) => value.length <= 15 || "15 carácteres máximos",
    },
  }),
  created() {
    this.initData();
  },
  computed: {
    clientErrors() {},
    patentErrors() {},
    loading() {
      return this.$store.state.loadingService;
    },
    uploading() {
      return this.$store.state.uploadingService;
    },
    dateFormatted() {
      const date = this.date;
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  watch: {
    loading(v) {
      if (v == false) {
        const service = this.$store.state.selectedService;

        this.client = service.client;
        this.patent = service.patent;
        this.date = service.date;
        this.month = service.date.substr(0, 7);
        this.pdf = service.pdf;
      }
    },
    date(v) {
      this.month = v.substr(0, 7);
    },
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let service = {};
        service.client = this.client;
        service.patent = this.patent.toUpperCase();
        service.date = this.date;
        service.month = this.month;
        service.pdf = !!this.pdf ? this.pdf : null;
        let data = {};
        data.service = service;
        data.id = this.$route.params.id;
        data.file = this.file;
        this.$store.dispatch("updateService", data);
      }
    },
    initData() {
      if (!!this.$route.params.id) {
        this.$store.dispatch("selectService", this.$route.params.id);
      }
    },
  },
};
</script>

<style>
iframe {
  border: 0;
}
</style>